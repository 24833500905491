import { useThemeParams, useExpand, useInitData } from '@vkruglikov/react-telegram-web-app';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const IndexPage = () => {
    const [isExpanded, expand] = useExpand();
    const handleClick = () => !isExpanded && expand();

    const [webapp] = useInitData();
    console.log(webapp.user);

    useEffect(() => { handleClick() }, [])

    const [colorScheme, themeParams] = useThemeParams();

    // console.log(colorScheme === 'light');
    // console.log(themeParams);

    return (
        <>
            <div className='bg-black font-mono text-white h-screen m-auto flex items-center justify-center flex-col'>
                <div className='text-xl text-center m-3 flex flex-col gap-5'>
                    <button className='bg-green-500 py-2 px-3 rounded-full shadow-green-500'>
                        <Link to={'/snake'}>Goto A Game</Link>
                    </button>
                    <button className='bg-green-500 py-2 px-3 rounded-full shadow-green-500'>
                        <Link to={'/snakev2'}>Goto A Game V2</Link>
                    </button>
                </div>
                <div className='border-sky-100 border-2 m-3 p-2 rounded-lg bottom-0 w-min text-center'>
                    <h2>User Info</h2>
                    <input className='text-black pt-1 mt-3 rounded-md text-center bg-white' value={webapp.user.id} />
                    <input className='text-black pt-1 mt-3 rounded-md text-center bg-white' value={webapp.user.first_name} readOnly />
                    {webapp.user.username && <input className='text-black pt-1 mt-3 rounded-md text-center bg-white' value={`@${webapp.user.username}`} readOnly />}
                    {webapp.user.photo_url && <input className='text-black pt-1 mt-3 rounded-md text-center bg-white' value={webapp.user.photo_url} readOnly />}
                </div>
            </div>
        </>
    );
}

export default IndexPage;