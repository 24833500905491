import './App.css';
import IndexPage from './pages';
import SnakeGame from './pages/SnakeGame';
import SnakeGameV2 from './pages/SnakeGameV2';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<IndexPage />} />
				<Route path='/snake' element={<SnakeGame />} />
				<Route path='/snakev2' element={<SnakeGameV2 />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
