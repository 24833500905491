import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import { ArrowUp, ArrowDown, ArrowLeft, ArrowRight } from 'lucide-react';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';

const GRID_SIZE = 20;
const CELL_SIZE = Math.min(20, (window.innerWidth - 55) / GRID_SIZE);
const INITIAL_SNAKE = [{ x: 10, y: 10 }];
const INITIAL_DIRECTION = { x: 1, y: 0 };
const INITIAL_FOOD = { x: 15, y: 15 };
const GAME_SPEED = 150; // milliseconds per move

const SnakeGame = () => {
	const [snake, setSnake] = useState(INITIAL_SNAKE);
	const [direction, setDirection] = useState(INITIAL_DIRECTION);
	const [food, setFood] = useState(INITIAL_FOOD);
	const [gameOver, setGameOver] = useState(false);
	const [score, setScore] = useState(0);
	const [godMode, setGodMode] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [showDebug, setShowDebug] = useState(false);
	const [trail, setTrail] = useState([]);
	const [impactOccurred] = useHapticFeedback();

	const nextDirection = useRef(INITIAL_DIRECTION);
	const gameLoopRef = useRef(null);

	const checkCollision = useCallback((head, snakeBody) => {
		if (!godMode && (head.x < 0 || head.x >= GRID_SIZE || head.y < 0 || head.y >= GRID_SIZE)) {
			return true;
		}
		return snakeBody.slice(1).some(segment => segment.x === head.x && segment.y === head.y);
	}, [godMode]);


	// notificationOccurred("success")

	const wrapPosition = useCallback((pos) => {
		return {
			x: (pos.x + GRID_SIZE) % GRID_SIZE,
			y: (pos.y + GRID_SIZE) % GRID_SIZE
		};
	}, []);

	const moveSnake = useCallback(() => {
		if (gameOver || !isPlaying) return;

		setSnake(currentSnake => {
			const head = godMode
				? wrapPosition({
					x: currentSnake[0].x + nextDirection.current.x,
					y: currentSnake[0].y + nextDirection.current.y
				})
				: {
					x: currentSnake[0].x + nextDirection.current.x,
					y: currentSnake[0].y + nextDirection.current.y
				};

			if (checkCollision(head, currentSnake)) {
				setGameOver(true);
				return currentSnake;
			}

			const newSnake = [head, ...currentSnake];

			if (head.x === food.x && head.y === food.y) {
				setScore(prevScore => prevScore + 1);
				setFood({
					x: Math.floor(Math.random() * GRID_SIZE),
					y: Math.floor(Math.random() * GRID_SIZE)
				});
			} else {
				newSnake.pop();
			}

			setTrail(prevTrail => [...prevTrail, head].slice(-10));

			return newSnake;
		});

		setDirection(nextDirection.current);
	}, [food, gameOver, isPlaying, checkCollision, wrapPosition, godMode]);

	useEffect(() => {
		if (isPlaying) {
			gameLoopRef.current = setInterval(moveSnake, GAME_SPEED);
		}
		return () => clearInterval(gameLoopRef.current);
	}, [moveSnake, isPlaying]);

	const handleDirectionChange = useCallback((newDirection) => {
		impactOccurred("rigid");
		if (
			(newDirection.x !== -direction.x || newDirection.x === 0) &&
			(newDirection.y !== -direction.y || newDirection.y === 0)
		) {
			nextDirection.current = newDirection;
		}
	}, [direction]);

	useEffect(() => {
		const handleKeyPress = (e) => {
			if (!isPlaying) return;
			impactOccurred("rigid");
			switch (e.key) {
				case 'ArrowUp': handleDirectionChange({ x: 0, y: -1 }); break;
				case 'ArrowDown': handleDirectionChange({ x: 0, y: 1 }); break;
				case 'ArrowLeft': handleDirectionChange({ x: -1, y: 0 }); break;
				case 'ArrowRight': handleDirectionChange({ x: 1, y: 0 }); break;
				default: break;
			}
		};

		window.addEventListener('keydown', handleKeyPress);
		return () => window.removeEventListener('keydown', handleKeyPress);
	}, [handleDirectionChange, isPlaying]);

	const resetGame = () => {
		impactOccurred("rigid");
		setSnake(INITIAL_SNAKE);
		nextDirection.current = INITIAL_DIRECTION;
		setDirection(INITIAL_DIRECTION);
		setFood(INITIAL_FOOD);
		setGameOver(false);
		setScore(0);
		setIsPlaying(true);
		setTrail([]);
	};

	const toggleGodMode = () => {
		setGodMode(prev => !prev);
	};

	const toggleDebug = () => {
		setShowDebug(prev => !prev);
	};

	const ControlButton = ({ direction, icon: Icon }) => (
		<button
			className="bg-gray-700 p-4 w-100 h-20 focus:outline-none focus:ring-2 focus:ring-green-500 transition-colors hover:bg-gray-600"
			onClick={() => handleDirectionChange(direction)}
		>
			<Icon className="text-green-500 w-full h-full" />
		</button>
	);


	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white font-mono sm:p-6 md:p-8">
			<div className="w-full max-w-lg p-3 sm:p-4 md:p-6 bg-black rounded-lg shadow-lg h-screen">

				<div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0">
					{/* <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green-500">Neon Snake</h1> */}
					<div className="flex space-x-2">
						<button className={`px-2 py-1 text-xs sm:text-sm rounded ${!godMode ? 'bg-green-500 text-white' : 'bg-gray-700'}`} onClick={toggleGodMode}>
							Wall Mode: {!godMode ? 'ON' : 'OFF'}
						</button>
						{/* <button className="px-2 py-1 text-xs sm:text-sm rounded bg-gray-700" onClick={toggleDebug} >
							Debug
						</button> */}
					</div>
				</div>


				<div className="mb-4 text-2xl">Score: <span className="text-green-500">{score}</span></div>
				<div
					className="relative border-2 border-green-500 mx-auto"
					style={{
						width: Math.min(GRID_SIZE * CELL_SIZE, window.innerWidth - 40),
						height: Math.min(GRID_SIZE * CELL_SIZE, window.innerWidth - 40),
						boxShadow: '0 0 10px #0f0, 0 0 20px #0f0, 0 0 30px #0f0, 0 0 40px #0f0'
					}}
				>
					{trail.map((segment, index) => (
						<div
							key={`trail-${index}`}
							className="absolute bg-green-300 rounded-full opacity-30"
							style={{
								left: segment.x * CELL_SIZE,
								top: segment.y * CELL_SIZE,
								width: CELL_SIZE,
								height: CELL_SIZE,
								transition: 'all 0.1s ease-out',
							}}
						/>
					))}

					{snake.map((segment, index) => (
						<div
							key={`${segment.x}-${segment.y}-${index}`}
							className={`absolute rounded-full ${index === 0 ? 'bg-yellow-400' : 'bg-green-500'}`}
							style={{
								left: segment.x * CELL_SIZE,
								top: segment.y * CELL_SIZE,
								width: CELL_SIZE,
								height: CELL_SIZE,
								boxShadow: index === 0
									? '0 0 5px #0ff, 0 0 10px #0ff'
									: '0 0 5px #0f0, 0 0 10px #0f0'
							}}
						/>
					))}


					<motion.div
						key={`food-${food.x}-${food.y}`}
						animate={{ scale: [1, 1.1, 1] }}
						transition={{ duration: 0.5, repeat: Infinity }}
						className="absolute bg-red-500 rounded-full"
						style={{
							left: food.x * CELL_SIZE,
							top: food.y * CELL_SIZE,
							width: CELL_SIZE,
							height: CELL_SIZE,
							boxShadow: '0 0 5px #f00, 0 0 10px #f00'
						}}
					/>
				</div>
				{showDebug && (
					<div className="mt-4 text-sm">
						<p>Snake Head: ({snake[0].x}, {snake[0].y})</p>
						<p>Direction: ({direction.x}, {direction.y})</p>
						<p>Food: ({food.x}, {food.y})</p>
						<p>GOD Mode: {godMode ? 'ON' : 'OFF'}</p>
					</div>
				)}

				{/* Mobile Controls */}
				<div className="mt-6 grid grid-cols-3 gap-4 justify-items-center max-w-xs mx-auto">
					<div></div>
					<ControlButton direction={{ x: 0, y: -1 }} icon={ArrowUp} />
					<div></div>
					<ControlButton direction={{ x: -1, y: 0 }} icon={ArrowLeft} />
					<div className="w-20 h-20"></div>
					<ControlButton direction={{ x: 1, y: 0 }} icon={ArrowRight} />
					<div></div>
					<ControlButton direction={{ x: 0, y: 1 }} icon={ArrowDown} />
					<div></div>
				</div>

			</div>
			{(gameOver || !isPlaying) && (
				<div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4">
					<div className="bg-gray-900 p-4 sm:p-6 md:p-8 rounded-lg text-center border-2 border-green-500 w-full max-w-sm">
						<h2 className="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-green-500">{gameOver ? 'Game Over!' : 'Neon Snake'}</h2>
						<p className="text-lg sm:text-xl mb-4 sm:mb-6">{gameOver ? `Your Score: ${score}` : 'Ready to play?'}</p>
						<button
							className="px-4 sm:px-6 py-2 sm:py-3 bg-green-500 text-black rounded font-bold hover:bg-green-400 transition text-sm sm:text-base"
							onClick={resetGame}
						>
							{gameOver ? 'Play Again' : 'Start Game'}
						</button>
					</div>
				</div>

			)}
		</div>
	);
};

export default SnakeGame;